











import { defineComponent, PropType, inject, useContext, reactive, ref, unref, watch } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaPrefetchManyStoreEffect extends StoryblokComponent<'prefetch-many-store-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  inject_item_name: string
  id_property: string
  list_property_path: string
  store_params_property: string
  store_repository: string
  delay: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaPrefetchManyStoreEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const resultList = reactive([])
    const injectName = props.blok.inject_item_name || 'item'
    const injectItem = inject(injectName, [])
    const visible = ref(false)

    const getIds = () => {
      if (injectItem == null) {
        return []
      }

      const unrefed = unref(injectItem)
      if (unrefed && unrefed.length > 0) {
        const injectedUsersList = get(unrefed, props.blok.list_property_path, {
          default: Array.isArray(unrefed) ? unrefed : [unrefed]
        })
        const ids = []
        visible.value = true

        for (const user of injectedUsersList) {
          if (user[props.blok.id_property]) {
            ids.push(+user[props.blok.id_property])
          }
        }

        return ids
      }
      return []
    }

    let fetching = true
    const fetchData = () => {
      if (fetching) {
        return
      }

      fetching = true
      resultList.splice(0, resultList.length)
      const ids = getIds()

      if (ids.length === 0) {
        fetching = false
        return
      }

      const payload = {
        [props.blok.store_params_property]: ids.toString()
      }

      nuxtContext.$bus.emit('refetchStore', {
        type: props.blok.store_repository,
        params: payload
      })

      fetching = false
    }

    watch(injectItem, fetchData)

    setTimeout(() => {
      fetching = false
      fetchData()
    }, parseInt(props.blok.delay))

    return {
      classes,
      dashify,
      visible
    }
  }
})
