import { Model } from '@vuex-orm/core'
import Game from '~/models/Game'
import Calendar from '~/models/Calendar'
import AppointmentRoom from '~/models/AppointmentRoom'
import Appointment from '~/models/Appointment'
import Country from "~/models/Country";

export default class UserCoachProfile extends Model {
  static entity = 'userCoachProfiles'

  static fields() {
    return {
      id: this.uid(),
      user_id: this.attr(null),
      calendar_id: this.attr(null),
      shared_calendar_ids: this.attr([]),
      language_ids: this.attr([]),
      game_ids: this.attr([]),
      introduction: this.string('').nullable(),
      games: this.hasManyBy(Game, 'game_ids'),
      languages: this.hasManyBy(Country, 'language_ids'),
      calendar: this.belongsTo(Calendar, 'calendar_id'),
      sharedCalendars: this.belongsTo(Calendar, 'shared_calendar_ids'),
      appointmentRooms: this.hasMany(AppointmentRoom, 'calendarID', 'calendar_id'),
      appointments: this.hasMany(Appointment, 'calendarID', 'calendar_id')
    }
  }

  id!: number
  user_id!: number
  calendar_id!: number
  shared_calendar_ids!: number[]
  language_ids!: number[]
  game_ids!: number[]
  introduction!: string | null
  games!: Game[]
  languages!: Country[]
  calendar!: Calendar | null
  sharedCalendars!: Calendar[]
  appointments!: Appointment[]
  appointmentRooms!: AppointmentRoom[]
}
