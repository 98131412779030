


























import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  ref,
  unref,
  useContext,
  watch
} from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import User from '~/models/User'
import useStoryblok from '~/utils/storyblok'

export interface GaButton extends StoryblokComponent<'button'> {
  content: StoryblokComponent<any>[]
  action_content: StoryblokComponent<any>[]
  custom_classes: string
  action_template_id: string
  action: string
  custom_action: string
  action_validate_form: boolean
  action_json: string
  action_include_data_model: boolean
  name: string
  type: 'button' | 'submit' | 'reset'
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaButton>,
      default: () => {}
    }
  },
  setup(props, context) {
    const { concatClasses, dashify, stringToJson } = useHelper()
    const { getTemplate } = useStoryblok()
    const nuxtContext = useContext()
    const buttonType = ref(props.blok.type)
    const isValid = ref(true)
    const dataModel = inject('dataModel', {})
    const userData: ComputedRef<User> | undefined = inject('userData')
    const form = inject('form', {})
    const isSubmitting = inject('isSubmitting', false)
    const isSubmitDisabled = inject('isSubmitDisabled', false)
    const hasAction =
      (props.blok.custom_action || props.blok.action) && (props.blok.custom_action || props.blok.action) !== ''

    const classes = concatClasses(props.blok)
    const disabled = computed(() => {
      return unref(isSubmitting) || unref(isSubmitDisabled)
    })

    if (props.blok.action === 'openFormModal') {
      watch(
        () => props.blok.action_json,
        () => {
          nuxtContext.$bus.emit('updateModal', stringToJson(props.blok.action_json))
        }
      )
    }

    const emitAction = (action = props.blok.custom_action || props.blok.action) => {
      nuxtContext.$bus.emit(action, {
        ...(props.blok.action_include_data_model &&
          dataModel !== {} &&
          Object.keys(dataModel).length > 0 && { dataModel }),
        ...(props.blok.action_template_id && {
          content: getTemplate(props.blok.action_template_id, { context: nuxtContext }),
          takeInnerContent: true
        }),
        ...(props.blok.action_content &&
          props.blok.action_content.length > 0 && { content: props.blok.action_content }),
        ...(props.blok.action_json && { ...stringToJson(props.blok.action_json) })
      })
    }

    const handleClick = async (event: any) => {
      if (hasAction) {
        event.preventDefault()
        if (props.blok.action_validate_form) {
          isValid.value = await form.value.validate()
        }
        if (unref(isValid)) {
          if (props.blok.action === 'subscriptionCheck') {
            const user = unref(userData)
            if (user && user.subscriptions.length > 0) {
              dataModel.payment_method = user.subscriptions[0].payment_method
              await form.value.$parent.onSubmit()
            } else {
              emitAction('openFormModal')
            }
          } else {
            emitAction()
          }
        }
      }
      context.emit('click', event)
    }

    return {
      classes,
      dashify,
      isSubmitting,
      isSubmitDisabled,
      disabled,
      handleClick,
      buttonType
    }
  }
})
