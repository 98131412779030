





















































































import {
  defineComponent,
  PropType,
  ref,
  useContext,
  computed,
  reactive,
  onBeforeMount,
  useRouter,
  useRoute,
  onMounted,
  watch
} from '@nuxtjs/composition-api'
import Order from '@/models/Order'
import Room from '@/models/AppointmentRoom'
import Appointments from '@/models/Appointment'
import { formatDate } from '@/utils/date'
import useAppointments from '@/composables/useAppointments'
import AppointmentFilter from '@/components/calendar/Filter'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useHelper from '@/utils/helper'

export interface Calendar extends StoryblokComponent<'caelndar'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  components: {
    AppointmentFilter
  },
  props: {
    blok: {
      type: Object as PropType<Calendar>,
      default: () => {}
    }
  },
  setup(props) {
    const { store, $dayjs, $bus } = useContext()
    const route = useRoute()
    const router = useRouter()
    const { rooms } = useAppointments()
    const { concatClasses, dashify } = useHelper()
    const classes = concatClasses(props.blok)

    const range = reactive({
      start: $dayjs().format(),
      end: $dayjs().add(30, 'day').format()
    })

    const modelConfig = {
      start: {
        timeAdjust: '00:00:00'
      },
      end: {
        timeAdjust: '23:59:59'
      }
    }

    const isProgressBooking = ref(false)
    const isProgressCancellation = ref(false)
    const events = computed(() => store.state.calendar.events || [])
    const isBusy = ref(false)
    const loggedIn = computed(() => store.state.auth.loggedIn)
    const appointments = computed(() => store.$repo(Appointments).all())
    const orders = computed(() => store.$repo(Order).all())
    const calendar = ref(null)
    const attributes = reactive([{ key: 'today', highlight: true }])

    const date = reactive({
      minDate: $dayjs().unix(),
      maxDate: $dayjs().add(1, 'month').unix()
    })

    function sameTime(time, index) {
      return $dayjs(time).isSame(rooms.value[index + 1]?.time, 'day')
    }

    const selectedEvent = computed(() => {
      const event = store.state.calendar.event
      return Object.keys(event).length > 0
    })

    function dayClicked(event) {
      const { start, end } = event
      date.minDate = $dayjs(start).unix()
      date.maxDate = $dayjs(end).unix()
      attributes[0].highlight = false
      router.push({
        path: route.value.path,
        query: {
          coach: route.value.query.coach,
          type: route.value.query.type,
          ...date,
          perPage: 10
        }
      })
    }

    onMounted(() => {
      router.push({
        path: route.value.path,
        query: {
          coach: null,
          types: null,
          minDate: $dayjs().unix(),
          maxDate: $dayjs().add(1, 'month').unix(),
          perPage: 10
        }
      })
    })

    function reset() {
      attributes[0].highlight = true
      date.minDate = $dayjs().unix()
      date.maxDate = $dayjs().add(1, 'month').unix()
      range.start = $dayjs()
      range.end = $dayjs().add(30, 'day')

      router.push({
        path: route.value.path,
        query: {
          coach: route.value.query.coach,
          type: route.value.query.type,
          ...date,
          perPage: 10
        }
      })
      store.$repo(Room).with('appointments').flush()
      $bus.emit('refetchStore', 'appointmentRooms')
    }

    onBeforeMount(() => {
      $bus.emit('refetchStore', 'appointmentTypes')
      $bus.emit('refetchStore', 'appointments')
      $bus.emit('refetchStore', 'games')
      $bus.emit('refetchStore', 'coaches')
      $bus.emit('refetchStore', 'appointmentRooms')
    })

    watch(
      rooms,
      (val) => {
        return val
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(route, (currentRoute) => {
      if (Object.keys(currentRoute.query).length === 0) {
        router.push({
          path: route.value.path,
          query: {
            coach: null,
            types: null,
            minDate: $dayjs().unix(),
            maxDate: $dayjs().add(1, 'month').unix(),
            page: null,
            perPage: 10
          }
        })
      }
    })

    return {
      classes,
      dashify,
      events,
      dayClicked,
      range,
      loggedIn,
      formatDate,
      isBusy,
      selectedEvent,
      reset,
      appointments,
      orders,
      isProgressBooking,
      isProgressCancellation,
      rooms,
      sameTime,
      calendar,
      attributes,
      modelConfig
    }
  }
})
