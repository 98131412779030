var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"dark:bg-gray-800 bg-gray-50"},[_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"max-w-7xl mx-auto sm:px-6 lg:px-8"},[_c('div',{staticClass:"shadow-xl sm:rounded-2xl"},[_c('div',{staticClass:"relative sm:rounded-t-2xl sm:overflow-hidden"},[(_vm.blok.image && _vm.blok.image.filename)?_c('div',{staticClass:"absolute inset-0 overflow-hidden"},[_c('blok-image',{attrs:{"blok":{
              source: _vm.blok.image,
              type: 'nuxt-img',
              custom_classes: 'h-full w-full object-cover',
              smart: true,
              width: 1216
            }}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 bg-gray-400 mix-blend-multiply"})],1):_vm._e(),_vm._v(" "),_c('blok-link',{attrs:{"blok":{
            link: _vm.blok.image_link,
            content: [
              {
                component: 'container',
                custom_classes: 'relative px-4 pb-24 pt-4 sm:px-6 sm:pb-48 sm:pt-2 lg:pb-96 lg:pt-8 lg:px-8',
                content: [
                  {
                    component: 'simple-text',
                    custom_classes:
                      'text-center text-3xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white opacity-75',
                    text: _vm.blok.title,
                    type: 'h1'
                  }
                ]
              }
            ]
          }}})],1),_vm._v(" "),_c('div',{staticClass:"z-20 relative mx-auto px-4 text-center sm:px-6 lg:px-8 lg:max-w-7xl bg-gray-200 dark:bg-gray-600"},[_c('div',{staticClass:"grid grid-cols-1 sm:gap-8 sm:grid-cols-2"},[_c('div',{},[_c('div',{staticClass:"flow-root px-6"},[(_vm.author)?_c('div',{staticClass:"sm:flex sm:items-end sm:space-x-5"},[(_vm.author.image && _vm.author.image.filename)?_c('div',{staticClass:"flex"},[_c('blok-image',{attrs:{"blok":{
                      source: _vm.author.image,
                      width: 100,
                      smart: true,
                      type: 'nuxt-img',
                      custom_classes:
                        'h-24 w-24 rounded-full ring-4 ring-gray-600 sm:h-32 sm:w-32 bg-gray-100 dark:bg-gray-800'
                    }}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1"},[_c('div',{staticClass:"sm:hidden md:block mt-6 min-w-0 flex-1"},[_c('h2',{staticClass:"text-2xl font-bold text-gray-900 truncate"},[_vm._v("\n                      "+_vm._s(_vm.author.prename)+" "+_vm._s(_vm.author.lastname)+"\n                    ")])])])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"ml-4 my-4 sm:text-right"},_vm._l((_vm.blok.categories),function(category){return _c('span',{key:category._uid,staticClass:"\n                inline-flex\n                items-center\n                px-2.5\n                py-0.5\n                rounded-md\n                text-md\n                font-medium\n                bg-secondary-500\n                text-gray-200\n                shadow-xl\n                mr-2\n              "},[_vm._v("\n              "+_vm._s(category.name)+"\n            ")])}),0)])]),_vm._v(" "),(_vm.blok.text)?_c('div',{staticClass:"p-4 dark:bg-gray-700 bg-gray-100"},[_c('blok-text',{attrs:{"blok":{
            custom_classes: 'mx-auto',
            text: _vm.blok.text
          }}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }