/* eslint-disable */
import { computed, ComputedRef, reactive, ref, toRefs, unref, watch } from '@nuxtjs/composition-api'
import { useWindowScroll, useWindowSize } from '@vueuse/core'
import UserRepository from '~/repositories/UserRepository'
import { Item } from '@vuex-orm/core'
import User from '~/models/User'
import GameAccount from '~/models/GameAccount'
import dayjs from 'dayjs'
import { DatasourceEntry } from './storyblok'

export interface SkillTag extends DatasourceEntry {
  vote: '+' | '-' | ''
}

export default function () {
  const isValidJson = (json: string) => {
    try {
      JSON.parse(json)
    } catch (e) {
      return false
    }
    return true
  }

  const stringToJson = (json: string) => {
    if (json === null || json === undefined || json === '') {
      return {}
    }
    if (!isValidJson(json)) {
      console.warn('Not valid json found', json)
      return {}
    }
    return JSON.parse(json.replace(/\r?\n|\r/g, ''))
  }

  const mergeObjects = (obj1: { [x: string]: any }, obj2: { [x: string]: any }) => {
    let answer: { [x: string]: any } = {}
    for (let key in obj1) {
      if (answer[key] === undefined || answer[key] === null) answer[key] = obj1[key]
    }
    for (let key in obj2) {
      if (answer[key] === undefined || answer[key] === null) answer[key] = obj2[key]
    }
    return answer
  }


  const checkScopes = (nuxtContext: any, scopes: string[] | string) => {
    const auth = nuxtContext.$auth

    const userData: ComputedRef<User> = computed(() => nuxtContext.store
        .$repo(User)
        .with('subscriptions', (query: any) => {
          query.with('features')
        })
        .find(nuxtContext.store.state.auth.user)
    )

    const games: ComputedRef<string[]> = computed(() => {
      return nuxtContext.store
        .$repo(GameAccount)
        .query()
        .where('user_id', userData.value?.id)
        .get()
        .map((acc: GameAccount) => acc.game_name)
    })

    if (typeof scopes === undefined) {
      scopes = []
    }
    if (typeof scopes === 'string') {
      scopes = [scopes]
    }
    let hasValidScope = true
    if (scopes.length) {

      if (scopes.findIndex((str)=>str.startsWith('gameAccount:')) !== -1 && (typeof(games.value) === 'undefined' || games.value.length === 0)) {
        nuxtContext.$bus.emit('refetchStore', 'gameAccounts')
      }

      scopes.forEach((scope) => {
        if (scope === '') {
          return
        }
        if (hasValidScope) {
          let kv = scope.split(':')
          let value
          if (kv.length>1) {
            scope = kv[0]
            value = kv[1]
          }

          switch (scope) {
            case 'registered':
              hasValidScope = auth.loggedIn
              break
            case 'notRegistered':
              hasValidScope = !auth.loggedIn
              break
            case 'verified':
              // uncomment if you have verification
              // hasValidScope = auth.loggedIn && user?.email_verified_at !== null
              hasValidScope = auth.loggedIn
              break
            case 'gameAccount':
              try {
                hasValidScope = typeof(value) !== 'undefined' && unref(games).indexOf(value) >= 0;
              } catch (e) {
                console.error("Error while checking game account scope: " + e);
              }
              break
            default:
              hasValidScope =
                !!unref(userData) &&
                !!unref(userData).subscriptions &&
                !!unref(userData).subscriptions.length &&
                unref(userData).subscriptions[0].features.find((feature) => feature.tag === scope)?.value === 'true'
              break
          }
        }
      })
    }

    return hasValidScope
  }

  const dashify = (value: string): string => {
    if (typeof value === 'undefined') {
      return 'undefined'
    }

    const dashified = value
      .toString()
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
      .replace(/[ _]/g, '-')

    return 'blok-' + dashified
  }

  const formatToAnchor = (anchor?: string) => {
    if (typeof anchor === 'undefined' || anchor === '') {
      return ''
    }
    return `#${anchor}`
  }

  const getButtonClasses = (props: { [x: string]: any }) => {
    let defaultStyles: Record<string, any> = []
    let typeStyles: Record<string, any> = []
    let buttonWrap = ''
    let textColor = ''

    buttonWrap = props.content_alignment
    textColor = props.text_color

    if (textColor === 'auto') {
      switch (props.button_type) {
        case 'primary':
          textColor = 'white'
          break
        case 'secondary':
          textColor = 'dark'
          break
        default:
          textColor = 'white'
      }
    }

    if (props.button_type === 'primary') {
      typeStyles = [
        `bg-${props.color}-500`,
        `hover:bg-${props.color}-300`,
        `active:bg-${props.color}-300`,
        `focus:bg-${props.color}-300`
      ]
    }

    defaultStyles = [
      `flex`,
      `whitespace-no-wrap`,
      `outline-none`,
      `px-6`,
      `py-3`,
      `rounded-sm`,
      `font-bold`,
      `uppercase`,
      `justify-center`,
      `border-3`,
      `text-${textColor}`,
      `border-${props.color}-500`,
      `hover:border-${props.color}-300`,
      `active:border-${props.color}-300`,
      `active:shadow-outline-${props.color}-200`,
      `focus:shadow-outline-${props.color}-200`
    ]

    return {
      type_style_classes: typeStyles,
      button_wrap_classes: buttonWrap,
      default_classes: defaultStyles
    }
  }

  /**
   * filters given props for keys with 'class' or 'color' and concats it's classes (removes duplicates)
   *
   * @param props
   */
  const concatClasses = (props: { [x: string]: any }) => {
    const classes = Object.keys(props).filter((type) => type.includes('custom_classes') || type.includes('color'))
    let combinedClasses: any[] = []

    if (!classes) {
      return []
    }

    classes.forEach((key) => {
      if (!Array.isArray(props[key])) {
        combinedClasses = [...combinedClasses, props[key]]
      } else {
        combinedClasses = [...combinedClasses, ...props[key]]
      }
    })

    return combinedClasses
  }

  const useScroll = () => {
    const { x, y } = process.browser ? useWindowScroll() : { ...toRefs(reactive({ x: 0, y: 0 })) }

    const state = reactive<{
      isUp: boolean
      isDown: boolean
    }>({
      isUp: false,
      isDown: false
    })

    watch(y, (newY, oldY) => {
      state.isUp = newY < oldY
      state.isDown = newY > oldY
    })

    return {
      x,
      y,
      ...toRefs(state)
    }
  }

  const isMobile = computed(() => {
    const { width } = useWindowSize()
    return width.value < 1024
  })

  const filterFaString = (value?: string) => {
    if (typeof value === 'undefined') {
      return 'undefined'
    }
    return value.toString().trim().replace(/fa-/g, '')
  }

  const scrollTo = (anchor: string): void => {
    setTimeout(() => {
      const offset = document.querySelector(`${anchor}`)

      if (offset) {
        const scrollTo = offset.getBoundingClientRect().top - 50 + window.scrollY // ~ Header height
        if ('scrollBehavior' in document.documentElement.style) {
          return window.scrollTo({ top: scrollTo, behavior: 'smooth' })
        } else {
          return window.scrollTo(0, scrollTo - 50) // ~ Header height
        }
      }
    }, 1)
  }

  const replacePlaceholder = (
    template: { [x: string]: any },
    placeholder:
      | { key?: RegExp; value: string; component?: string; property?: string; propertyValue?: string }[]
      | any[]
  ) => {
    if (!template || typeof template !== 'object') {
      return
    }

    Object.keys(template).forEach(function (key) {
      if (template[key] !== null && typeof template[key] === 'object') {
        replacePlaceholder(template[key], placeholder)
        return
      }
      if (typeof template[key] === 'string') {
        placeholder.forEach((placeholderObject) => {
          if (!placeholderObject.component && placeholderObject.key && placeholderObject.key.test(template[key])) {
            if (/.*\|date%/.test(template[key])) {
              placeholderObject.value = placeholderObject.value
                ? dayjs(placeholderObject.value).format('MM-DD-YYYY')
                : ''
            }

            if (/.*\|datetime%/.test(template[key])) {
              placeholderObject.value = placeholderObject.value
                ? dayjs(placeholderObject.value).format('YYYY-MM-DD HH:mm')
                : ''
            }

            if (/.*\|time%/.test(template[key])) {
              placeholderObject.value = placeholderObject.value
                ? dayjs(placeholderObject.value).format('HH:mm')
                : ''
            }

            template[key] = template[key].replace(placeholderObject.key, placeholderObject.value)
          }
          if (
            placeholderObject.component &&
            placeholderObject.property &&
            template[placeholderObject.property] &&
            template.component === placeholderObject.component
          ) {
            template[placeholderObject.property] = placeholderObject.value
          }
          if (
            placeholderObject.component &&
            placeholderObject.propertyValue &&
            placeholderObject.key &&
            placeholderObject.key.test(template[key]) &&
            placeholderObject.property &&
            template[placeholderObject.property] &&
            template.component === placeholderObject.component
          ) {
            template[key] = template[key].replace(placeholderObject.key, placeholderObject.value)
            template[placeholderObject.property] = placeholderObject.propertyValue
          }
        })
      }
    })

    return template
  }

  const checkConditionEffect = (valueToCheck: any, compareOperator: string, compareToValue: string) => {
    switch (compareOperator) {
      case 'isTrue':
        return valueToCheck === true
      case 'isFalse':
        return valueToCheck === false
      case 'exists': {
        if (Array.isArray(valueToCheck)) {
          return !!valueToCheck.length
        }
        return valueToCheck !== undefined && valueToCheck !== null
      }
      case 'notExists': {
        if (Array.isArray(valueToCheck)) {
          return !valueToCheck.length
        }
        return valueToCheck === undefined || valueToCheck === null
      }
      case '>':
        return parseInt(valueToCheck) > parseInt(compareToValue)
      case '<':
        return parseInt(valueToCheck) < parseInt(compareToValue)
      case 'eq':
        return valueToCheck?.toString() === compareToValue
      case 'notEq':
        return valueToCheck?.toString() !== compareToValue
      case 'in_x_minutes':
        const dateIn = dayjs(valueToCheck)
        if (dateIn.isValid() && !isNaN(parseInt(compareToValue))) {
          let minutes = dateIn.subtract(parseInt(compareToValue), 'minutes')
          return dayjs().isAfter(minutes)
        }
        return false
      case 'not_in_x_minutes':
        const dateNotIn = dayjs(valueToCheck)
        if (dateNotIn.isValid() && !isNaN(parseInt(compareToValue))) {
          let minutes = dateNotIn.subtract(parseInt(compareToValue), 'minutes')
          return dayjs().isBefore(minutes)
        }
        return false
      case 'notEqAndExists':
        var exists = Array.isArray(valueToCheck) ? (!!valueToCheck.length) : (valueToCheck !== undefined && valueToCheck !== null)
        return exists && valueToCheck?.toString() !== compareToValue
      default:
        return true
    }
  }

  const parseSkillTags = (tags: string, options: Array<DatasourceEntry>, feedbackVoteToOptions = false) => {
    const mapId = (tag:DatasourceEntry) => tag.name;
    const optionNames = options.map((tag:DatasourceEntry) => mapId(tag))
    const votedTags = tags.split(',')
    const tagsList = [] as Array<SkillTag>

    votedTags.forEach((votedTag) => {
      const splitted = votedTag.split(':').map((str)=>str.trim())

      if (splitted.length > 0) {
        const tag = {
          name: splitted[0]
        } as SkillTag

        if (splitted.length > 1) {
          tag.vote = splitted[1] as ('+' | '-' | '')
        } else {
          tag.vote = ''
        }

        const optionIdx = optionNames.indexOf(tag.name)
        if (optionIdx >= 0) {
          const tagToPush = {...tag, ...options[optionIdx]} // combine Tag with option
          tagsList.push(tagToPush)
          if (feedbackVoteToOptions) {
            options[optionIdx] = tagToPush
          }
        }
      }
    })

    return tagsList
  }

  const serializeSkillTags = (tagsList: Array<SkillTag>) => {
    return tagsList
      .map((tag) => tag.name + (tag.vote ? ':' + tag.vote: ''))
      .join(',')
  }

  return {
    dashify,
    useScroll,
    scrollTo,
    concatClasses,
    formatToAnchor,
    getButtonClasses,
    isMobile,
    filterFaString,
    replacePlaceholder,
    isValidJson,
    mergeObjects,
    checkScopes,
    stringToJson,
    checkConditionEffect,
    parseSkillTags,
    serializeSkillTags
  }
}
