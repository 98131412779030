var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"flex flex-col rounded-lg shadow-lg overflow-hidden"},[(_vm.content.image && _vm.content.image.filename)?_c('div',{staticClass:"flex-shrink-0"},[_c('blok-image',{attrs:{"blok":{
        type: 'nuxt-img',
        source: _vm.content.image,
        custom_classes: 'h-48 w-full object-cover'
      }}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1 bg-white dark:bg-gray-600 p-6 flex flex-col justify-between"},[_c('div',{staticClass:"flex-1"},[_c('p',{staticClass:"text-sm font-medium text-indigo-600"},_vm._l((_vm.content.categories),function(category){return _c('a',{key:category._uid,attrs:{"href":_vm.getRealPath(category.full_slug)}},[_c('span',{staticClass:"\n              inline-flex\n              items-center\n              px-2.5\n              py-0.5\n              rounded-md\n              text-md\n              font-medium\n              bg-primary-500\n              hover:bg-primary-300\n              text-gray-100\n              shadow-xl\n            "},[_vm._v("\n            "+_vm._s(category.name)+"\n          ")])])}),0),_vm._v(" "),_c('a',{staticClass:"block mt-2",attrs:{"href":_vm.getRealPath(_vm.blok.full_slug)}},[_c('h4',{staticClass:"text-xl font-semibold text-secondary-500"},[_vm._v("\n          "+_vm._s(_vm.content.title)+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"mt-3 text-base text-gray-500"},[_vm._v("\n          "+_vm._s(_vm.content.short_text)+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"mt-6 flex items-center"},[_c('div',{staticClass:"flex-shrink-0"},[(_vm.content.author && _vm.content.author.content.image)?_c('a',{attrs:{"href":_vm.getRealPath(_vm.content.author.full_slug)}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.content.author.content.prename)+" "+_vm._s(_vm.content.author.content.lastname))]),_vm._v(" "),(_vm.content.author.content.image.filename)?_c('blok-image',{attrs:{"blok":{
              type: 'nuxt-img',
              source: _vm.content.author.content.image,
              custom_classes: 'h-10 w-10 rounded-full'
            }}}):_c('svg',{staticClass:"h-10 w-10 text-gray-500 dark:text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z","clip-rule":"evenodd"}})])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"ml-3"},[(_vm.content.author && _vm.content.author.content)?_c('p',{staticClass:"text-sm font-medium text-gray-900"},[_c('a',{staticClass:"hover:underline",attrs:{"href":_vm.getRealPath(_vm.content.author.full_slug)}},[_vm._v("\n            "+_vm._s(_vm.content.author.content.prename)+" "+_vm._s(_vm.content.author.content.lastname)+"\n          ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex space-x-1 text-sm text-gray-500"},[_c('time',{attrs:{"datetime":"2020-03-10"}},[_vm._v("\n            "+_vm._s(_vm.content.first_published_at ? _vm.content.first_published_at : _vm.content.created_at)+"\n          ")]),_vm._v(" "),_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" · ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }