import { Model } from '@vuex-orm/core'
import dayjs from 'dayjs'
import Appointment from './Appointment'
import AppointmentType from '~/models/AppointmentType'
import Calendar from '~/models/Calendar'
import GameRank from '~/models/GameRank'
//import Game from '~/models/Game'
import User from '~/models/User'

export default class AppointmentRoom extends Model {
  static entity = 'appointmentRooms'

  static fields() {
    return {
      id: this.uid(),
      appointmentTypeID: this.attr(null),
      calendarID: this.attr(null),
      user_id: this.attr(null),
      name: this.string(''),
      language: this.string(''),
      calendar: this.string(''),
      topic: this.string('').nullable(),
      min_game_rank_id: this.attr(null),
      max_game_rank_id: this.attr(null),
      price: this.number(0),
      duration: this.number(0),
      canceled: this.boolean(false),
      location: this.string(''),
      time: this.string(''),
      bookable_till: this.string(''),
      starts_at: this.string(''),
      ends_at: this.string(''),
      slots: this.number(0),
      calendarTimezone: this.string(''),
      slotsAvailable: this.number(0),
      appointments: this.hasMany(Appointment, 'classID'),
      appointmentType: this.belongsTo(AppointmentType, 'appointmentTypeID'),
      calendarObject: this.belongsTo(Calendar, 'calendarID'),
      minRank: this.belongsTo(GameRank, 'min_game_rank_id'),
      maxRank: this.belongsTo(GameRank, 'max_game_rank_id'),
      owner: this.belongsTo(User, 'user_id')
    }
  }

  time!: string
  starts_at!: string
  ends_at!: string
  location!: string
  topic!: string
  minRank!: GameRank | null
  maxRank!: GameRank | null
  owner!: User | null
  appointmentType!: AppointmentType | null

  get timestamp() {
    return dayjs(this.time).unix()
  }

  get zoomLink() {
    return this.location.split('\n')[0]
  }
}
