import { Model } from '@vuex-orm/core'
import Appointment from "~/models/Appointment";
import AppointmentRoom from "~/models/AppointmentRoom";

export default class Calendar extends Model {
  static entity = 'calendars'

  static fields () {
    return {
      id: this.uid(),
      appointments: this.hasMany(Appointment, 'calendarID'),
      appointmentRooms: this.hasMany(AppointmentRoom, 'calendarID')
    }
  }
}
