






































































































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokLink } from '~/types/storyblok-vue'
import { GaImage } from '~/components/image/Image.vue'

export interface GaBlog extends StoryblokComponent<'blog'> {
  blok_style: StoryblokComponent<any>[]
  image: GaImage
  image_link: StoryblokLink
  custom_classes: string
  title: string
  categories: [id: string] | StoryblokComponent<any>[]
  author: string | StoryblokComponent<any>
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaBlog>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const classes = concatClasses(props.blok)

    const author = props.blok.author ? props.blok.author.content : false

    return {
      classes,
      dashify,
      author
    }
  },
  head: {}
})
