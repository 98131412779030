




import { defineComponent, provide } from '@nuxtjs/composition-api'
import useHelper from '~/utils/helper'

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    componentProps: {
      type: Object,
      default: () => {}
    },
    itemName: {
      type: String,
      default: 'item'
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    provideDataModel: {
      type: Boolean,
      default: false
    },
    dataModel: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { stringToJson } = useHelper()

    provide(props.itemName ? props.itemName : 'item', props.item)
    provide(props.itemName ? props.itemName + '_index' : 'item_index', props.itemIndex)
    if (props.provideDataModel) {
      provide('dataModel', stringToJson(props.dataModel.replace('{itemId}', props.item.id)))
    }
  }
})
