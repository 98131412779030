<template>
  <div class="group col-span-4 order-6 md:order-none md:inline mr-3">
    <button
      aria-haspopup="true"
      aria-controls="menu"
      :class="[
        'outline-none focus:outline-none px-12 py-2 bg-secondary-500 rounded flex justify-center min-w-32 w-full',
        active &&
          'bg-gradient-to-r from-blue-300 via-blue-400 to-rose-500 transition-all duration-300 hover:bg-blue-600'
      ]"
      @click="isToggled = !isToggled"
    >
      <div class="pr-1 font-black text-black text-l">{{ active === false ? 'COACH' : placeholder }}</div>
      <span>
        <svg
          :class="[
            'fill-black h-6 w-6 transform transition duration-150 ease-in-out',
            isToggled === true && 'group-hover:-rotate-180'
          ]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </span>
    </button>
    <ul
      id="menu"
      aria-hidden="true"
      :class="[
        'bg-white border transform scale-0 absolute transition duration-150 ease-in-out origin-top min-w-32 w-full md:w-48 z-50',
        isToggled === true && 'group-hover:scale-100'
      ]"
      @click="isToggled = false"
    >
      <li
        v-for="item in items"
        :key="item.id"
        class="w-full inline-block px-2 py-1 bg-gray-500 hover:bg-gray-700 text-white font-semibold cursor-pointer"
        @click="emitSelected(item)"
      >
        {{ item.calendar }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api'

export default {
  props: {
    items:  { type: Array },
    active: { type: Boolean }
  },
  setup(props, context) {
    const placeholder = ref('')
    const isToggled = ref(true)

    const emitSelected = (item) => {
      placeholder.value = item.calendar
      context.emit('selected', item.id)
    }
    return {
      placeholder,
      emitSelected,
      isToggled
    }
  }
}
</script>
