










import { defineComponent, inject, PropType, ref, unref } from '@nuxtjs/composition-api'
import { useLocalStorage } from '@vueuse/core'
import useHelper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface GaReplaceData extends StoryblokComponent<'replace-data-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaReplaceData>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const item = inject('item', ref({}))
    const currency = useLocalStorage('currency', 'EUR')

    const generateComponentItemAttributes = (component_style) => {
      return generateItemAttributes(
        {
          ...unref(item),
          geo_currency: currency.value === 'EUR' ? '€' : '$'
        },
        component_style
      )
    }

    return {
      classes,
      dashify,
      generateComponentItemAttributes
    }
  }
})
