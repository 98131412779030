



























import { defineComponent, inject, PropType } from '@nuxtjs/composition-api'
import helper from '@/utils/helper'
import { Link } from '~/components/link/Link.vue'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'

export interface NavigationMobile extends StoryblokComponent<'navigation-mobile'> {
  custom_classes: string
  link_style: Link[]
  content_down: StoryblokComponent<any>[]
  content_user_icon: StoryblokComponent<any>[]
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<NavigationMobile>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const { generateNavItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const { link_style } = props.blok

    const menuIsOpen = inject('mobileMenuOpen', false)
    const navigationItems = inject('navigationItems', [])

    const generateItemAttributes = (item: any) => {
      return generateNavItemAttributes(item, link_style)
    }

    return {
      classes,
      dashify,
      menuIsOpen,
      navigationItems,
      generateItemAttributes
    }
  }
})
