var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.blok.vimeo && _vm.blok.vimeo.vimeo_raw)?_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],class:_vm.classes},[_c('a',{staticClass:"relative max-w-max",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleModal.apply(null, arguments)}}},[_c('div',{staticClass:"relative"},[(_vm.blok.cover_image && _vm.blok.cover_image.filename)?_c('blok-image',{attrs:{"blok":{
          source: _vm.blok.cover_image,
          width: 640,
          height: 360,
          type: 'nuxt-img',
          custom_classes: 'h-full w-full object-cover'
        }}}):_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":_vm.videoJson ? _vm.videoJson.thumbnail_url : _vm.videoJsonBackup.thumbnail_url,"loading":"lazy","width":_vm.videoJson ? _vm.videoJson.thumbnail_width : _vm.videoJsonBackup.thumbnail_width,"height":_vm.videoJson ? _vm.videoJson.thumbnail_height : _vm.videoJsonBackup.thumbnail_height,"title":_vm.videoJson ? _vm.videoJson.title : _vm.videoJsonBackup.title}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 bg-gray-300 mix-blend-multiply"}),_vm._v(" "),_c('blok-icon',{attrs:{"blok":{
          icon_type: 'far',
          icon_name: 'play-circle',
          custom_classes: 'fa-5x absolute text-white inset-1/2 opacity-50 -ml-8 -mt-8 hover:opacity-75'
        }}})],1)]),_vm._v(" "),_c('blok-modal',{attrs:{"blok":{
      modal_classes: 'bg-transparent h-screen max-w-7xl',
      full_size: true
    },"open":_vm.isOpen},on:{"close":_vm.toggleModal}},[_c('vimeo-player',{staticClass:"aspect-w-16 h-screen sm:h-4/5",attrs:{"video-url":_vm.blok.vimeo.vimeo_raw,"options":{ responsive: false, controls: true, autoplay: true }}})],1)],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }