






























































import { defineComponent, PropType, ref, inject, set, useContext } from '@nuxtjs/composition-api'
import storyblok from '@/utils/storyblok'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaSkillTagsVoting extends StoryblokComponent<'SkillTagsVoting'> {
  custom_classes: string
  type: 'weaknesses' | 'strengths' | 'blindspots'
  model: string
  label: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaSkillTagsVoting>,
      default: () => {}
    }
  },
  setup(props, context) {
    const { concatClasses, parseSkillTags, serializeSkillTags } = helper()
    const { getDatasource } = storyblok()
    const nuxtContext = useContext()
    const dataModel = inject('dataModel', {})
    const classes = concatClasses(props.blok)
    const dimension = nuxtContext.i18n.locale
    const tagsList = ref([])

    // get available skill tags from CMS
    getDatasource('skilltags', dimension).then((skilltags) => {
      if (dataModel[props.blok.type]) {
        const options = skilltags.map((tag) => {
          // set dimension_value to default if no specific dimension
          tag.dimension_value = tag.dimension_value || tag.value
          return tag
        })

        if (options.length > 0) {
          tagsList.value = parseSkillTags(dataModel[props.blok.type], options, true)
        }
      }
    })

    const changeTagVote = (tagId: number, vote: string) => {
      tagsList.value[tagId].vote = vote
    }

    const handleInput = (data: string) => {
      if (dataModel && props.blok.type) {
        set(dataModel, props.blok.type, serializeSkillTags(tagsList.value))
      }
      context.emit('input', data)
    }

    return {
      classes,
      tagsList,
      changeTagVote,
      handleInput
    }
  }
})
