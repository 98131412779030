import { Model } from '@vuex-orm/core'

export default class GameRankProgress extends Model {
  static entity = 'gameRankProgresses'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      game_account_id: this.attr(null),
      game_rank_system_id: this.attr(null),
      rank_current: this.attr(null),
      rank_start: this.attr(null),
      rank_goal: this.attr(null),
      is_default: this.attr(null),
    }
  }

  id!: number
  name!: string
  game_account_id!: number
  game_rank_system_id!: number
  rank_current!: number | null
  rank_start!: number | null
  rank_goal!: number | null
  is_default!: boolean | null

}
