import { Model } from '@vuex-orm/core'
import Appointment from './Appointment'
import User from './User'
import Product from './Product'
import SubscriptionPlan from "~/models/SubscriptionPlan";

export default class Order extends Model {
  static entity = 'orders'

  static fields() {
    return {
      id: this.uid(),
      userId: this.attr(null),
      amount: this.number(0),
      productIds: this.attr([]),
      planIds: this.attr([]),
      appointment_id: this.attr(null),
      booking_attributes: this.attr([]),
      appointment: this.belongsTo(Appointment, 'appointment_id'),
      state: this.string(''),
      user: this.belongsTo(User, 'userId'),
      products: this.hasManyBy(Product, 'productIds'),
      plans: this.hasManyBy(SubscriptionPlan, 'planIds'),
      payment_method: this.string('').nullable(),
      used_voucher_code: this.string('').nullable()
    }
  }

  id!: number
  productIds!: number[]
  planIds!: number[]
  amount!: number
  state!: string
  plans!: SubscriptionPlan[]
  products!: Product[]
  payment_method!: string | null
  used_voucher_code!: string | null
}
