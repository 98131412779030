




































































































































































































import {
  defineComponent,
  computed,
  useContext,
  ref,
  useRouter,
  inject,
  PropType,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import Order from '@/models/Order'
import { formatDate } from '@/utils/date'
import AppointmentModel from '@/models/Appointment'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface AppointmentRoom extends StoryblokComponent<'AppointmentRoom'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<AppointmentRoom>,
      default: () => {}
    },
    room: {
      type: Object as PropType<AppointmentModel>,
      default: () => {},
      required: true
    }
  },
  setup(props) {
    const { store, $axios, $config, $bus, localePath, $dayjs } = useContext()
    const userData = inject('userData')
    const loggedIn = computed(() => store.state.auth.loggedIn)
    const isCoach = userData.value?.is_coach || false
    const { room } = toRefs(props)

    const location = computed(() => {
      if (isCoach && room.value.appointments && room.value.appointments.length > 0) {
        return (
          room.value.appointments
            .find((f) => f.calendarID === userData.value?.user_coach_profile?.calendar_id)
            ?.location.split('\n') || []
        )
      }
      return []
    })

    const zoomLink = computed(() => {
      if (isCoach && room.value.appointments && location.value.length > 0) {
        return location.value[0].replace('URL: ', '')
      }
      return ''
    })

    const showZoomLink = computed(() => {
      return (
        (room.value?.appointments.length > 0 &&
          isCoach &&
          room.value.calendarID === userData.value?.user_coach_profile?.calendar_id) ||
        room.value.appointments?.some((s) => s.user_id === userData.value.id)
      )
    })

    const users = computed(() => room.value.appointments.map((user) => user.user_id))

    const showUsers = () => {
      showMessageUsers.value = true
    }

    const showCancellation = () => {
      showCancelAppointment.value = true
    }

    const hasSubscriptions = computed(() => userData.value?.subscriptions.length > 0 || false)

    const orders = computed(() => store.$repo(Order).all())
    const isProgressBooking = ref(false)
    const isProgressCancellation = ref(false)
    const showVoucher = ref(false)
    const showMessageUsers = ref(false)
    const showCancelAppointment = ref(false)
    const router = useRouter()

    function isBooked(room) {
      if (
        (room.appointments.length > 0 &&
          room?.appointments.find((appointment) => appointment.user_id === userData.value?.id)) ||
        false
      ) {
        return true
      }
      return false
    }

    const stillBookableTimeLeft = (room: AppointmentModel) => {
      // console.log($dayjs(room.bookable_till).diff($dayjs()))
      return $dayjs(room.time).diff($dayjs())
    }

    function selectEvent() {
      if (!loggedIn.value) {
        router.push('login')
      }
      showVoucher.value = true
    }

    const getSkillLevel = (room: any) => {
      if (!room.minRank || !room.maxRank) {
        return 'All Ranks'
      }
      if (room.minRank && room.maxRank) {
        if (room.minRank === room.maxRank) return room.minRank.name
      }
      return room.minRank.name + ' - ' + room.maxRank.name
    }

    const checkout = async (id) => {
      const payload = {
        classId: id,
        timezone: $dayjs.tz.guess(),
        redirectUrlSuccess: $config.api + localePath('/confirmation')
      }
      if (loggedIn.value) {
        isProgressBooking.value = true
        await $axios
          .$post(`/api/payment/order/booking`, payload)
          .then((response) => (window.location.href = response.data.redirect_url))
      } else {
        router.push('login')
      }
    }

    async function cancelEvent(room) {
      isProgressCancellation.value = true
      const appointment = room?.appointments.find((appointment) => appointment.user_id === userData.value?.id)
      await $axios
        .$post(`/api/booking/appointments/${appointment.id}/cancel`)
        .then(() => {
          store.$repo(AppointmentModel).destroy(appointment.id)
          isProgressCancellation.value = false
        })
        .catch((e) => console.error(e))
        .finally(() => {
          $bus.emit('refetchStore', 'appointments')
          $bus.emit('refetchStore', 'appointmentRooms')
        })
      isProgressCancellation.value = false
      showCancelAppointment.value = false
    }

    const twelveHrs = 43200000

    function showCoachingLanguage(coach) {
      switch (coach) {
        case 'Martin':
          return ['de', 'uk']
        case 'Timo':
          return ['de']
        case 'Schnixx':
          return ['de', 'uk']
        case 'Kev1n':
          return ['de', 'uk']
        case 'Stylo':
          return ['de']
        case 'Lefti':
          return ['de']
        case 'SAF Cameron':
          return ['uk']
        case 'Alex Stange':
          return ['de']
        case 'Chris Kasian':
          return ['de']
        case 'Marvin':
          return ['de']
        case 'BurnleyDS':
          return ['uk']
        case 'Czapi':
          return ['de', 'uk']
        case 'Omar':
          return ['us']
        case 'Jeffryy':
          return ['de']
        case 'Affebanane':
          return ['de']
        case 'Mark':
          return ['de']
        case 'Tim':
          return ['de']
        case 'Mel':
          return ['de']
        case 'Sascha':
          return ['de', 'uk']
        case 'Patrick':
          return ['de', 'uk']
        case 'Broomey':
          return ['uk']
        case 'Maxi':
          return ['de']
        case 'SlyJayKay':
          return ['de']
        case 'DaredevilEA':
          return ['de']
        case 'BlampzZ':
          return ['uk']
        case 'YaBoyZBM':
          return ['uk']
      }
    }

    watch(room, (val) => {
      room.value = val
    })

    return {
      loggedIn,
      formatDate,
      twelveHrs,
      isBooked,
      stillBookableTimeLeft,
      orders,
      selectEvent,
      cancelEvent,
      isProgressBooking,
      isProgressCancellation,
      showVoucher,
      checkout,
      isCoach,
      zoomLink,
      showZoomLink,
      hasSubscriptions,
      showCoachingLanguage,
      users,
      showUsers,
      showMessageUsers,
      showCancelAppointment,
      showCancellation,
      getSkillLevel
    }
  }
})
