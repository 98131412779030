import { Model } from '@vuex-orm/core'
import GameRankSystem from '~/models/GameRankSystem';

export default class Game extends Model {
  static entity = 'games'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      description: this.string(''),
      game_rank_systems: this.hasMany(GameRankSystem, 'game_id'),
    }
  }

  name!: string
  description!: string
}
