<template>
  <client-only>
    <div
      v-if="isVisible || isStoryblokEdit"
      v-editable="blok"
      :class="[...classes, { 'border-red-500 border-2': isStoryblokEdit }]"
      v-bind="{
        ...(isStoryblokEdit && {
          title: 'Check if ' + valueToCheck + ' ' + blok.visibility_compare + ' ' + blok.compare_to_value
        })
      }"
    >
      <component
        :is="dashify(blokChild.component)"
        v-for="blokChild in blok.content"
        :key="blokChild._uid"
        :blok="blokChild"
      ></component>
    </div>
    <div v-else></div>
  </client-only>
</template>

<script>
import {
  defineComponent,
  watch,
  ref,
  inject,
  useStore,
  useRoute,
  onMounted,
  useContext,
  unref
} from '@nuxtjs/composition-api'
import get from 'get-value'
import { useLocalStorage } from '@vueuse/core'
import helper from '@/utils/helper'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const dataModel = inject('dataModel', {})
    const userData = inject('userData')
    const isVisible = ref(!props.blok.is_hidden)
    const isStoryblokEdit = ref(false)
    const nuxtContext = useContext()
    const store = useStore()
    const route = useRoute()
    const { concatClasses, dashify, stringToJson } = helper()
    const classes = concatClasses(props.blok)
    let valueToCheck = props.blok.visibility_field
      ? get(dataModel, props.blok.visibility_field)
      : route.value.query[props.blok.visibility_route_param]

    const getCompareTovalue = (compareToObject) => {
      compareToObject = stringToJson(compareToObject)
      switch (compareToObject.type) {
        case 'string':
          return String(compareToObject.value)
        case 'storeProperty':
          return get(store.state, compareToObject.value)
      }
    }

    const checkVisibility = () => {
      if (
        (!props.blok.visibility_field ||
          !props.blok.visibility_route_param ||
          !props.blok.check_user_data_property ||
          !props.blok.check_local_storage_property) &&
        !props.blok.visibility_compare
      ) {
        return true
      }
      if (props.blok.check_user_data_property) {
        valueToCheck = get(unref(userData), props.blok.check_user_data_property)
        if (!valueToCheck) {
          if (props.blok.show_if_not_logged_in) {
            return true
          }
        }
      }
      if (props.blok.check_local_storage_property) {
        valueToCheck = unref(useLocalStorage(props.blok.check_local_storage_property, true))
      }
      const compareToValue = getCompareTovalue(props.blok.compare_to_value)
      switch (props.blok.visibility_compare) {
        case 'isTrue':
          return valueToCheck === true
        case 'isFalse':
          return valueToCheck === false
        case 'exists': {
          if (Array.isArray(valueToCheck)) {
            return !!valueToCheck.length
          }
          return valueToCheck !== undefined
        }
        case 'notExists': {
          if (Array.isArray(valueToCheck)) {
            return !valueToCheck.length
          }
          return valueToCheck === undefined || valueToCheck === null
        }
        case '>':
          return valueToCheck > compareToValue
        case '<':
          return valueToCheck < compareToValue
        case 'eq':
          return valueToCheck === compareToValue
        case 'notEq':
          return valueToCheck !== compareToValue
        default:
          return true
      }
    }

    if (props.blok.visibility_route_param) {
      isVisible.value = checkVisibility()
    }

    if (nuxtContext.isDev || nuxtContext.$config.dev) {
      onMounted(() => {
        if (window.location !== window.parent.location) {
          isStoryblokEdit.value = true
        }
      })
    }

    isVisible.value = checkVisibility()

    watch(
      () => dataModel,
      () => {
        isVisible.value = checkVisibility()
      },
      {
        deep: true
      }
    )

    watch(
      () => userData,
      () => {
        isVisible.value = checkVisibility()
      },
      {
        deep: true
      }
    )

    watch(
      () => route.value.query,
      () => {
        isVisible.value = checkVisibility()
      },
      {
        deep: true
      }
    )

    return {
      classes,
      dashify,
      isVisible,
      isStoryblokEdit,
      valueToCheck
    }
  }
})
</script>
