<template>
  <div
    v-if="appointment && appointment.canceled === false"
    class="relative rounded-xl px-0 sm:px-6 py-2 flex items-center space-x-3 hover:bg-gray-50 dark:hover:bg-gray-500"
  >
    <div class="flex-shrink-0 hidden sm:flex">
      <blok-icon
        :blok="{
          icon: {
            icon: null
          },
          icon_type: 'fas',
          icon_name: 'calendar-alt',
          custom_classes: 'text-secondary-500 mr-4'
        }"
      />
    </div>
    <div class="flex-1 min-w-0">
      <a href="#" class="focus:outline-none">
        <p class="text-base font-medium dark:text-white text-gray-900">{{ appointment.type }}</p>
        <p class="text-base dark:text-white text-gray-600 truncate">
          {{ formatDate({ time: appointment.datetime, duration: appointment.duration }) }}
        </p>
      </a>
    </div>
    <button
      v-if="isCancelable"
      class="
        flex
        items-center
        bg-primary-500
        text-white text-md
        font-bold
        uppercase
        px-3
        py-2
        rounded
        shadow
        hover:shadow-md
        outline-none
        focus:outline-none
        lg:mr-1 lg:mb-0
      "
      style="transition: all 0.15s ease 0s"
      @click="toggleModal()"
    >
      <blok-icon
        :blok="{
          icon: {
            icon: null
          },
          icon_type: 'fas',
          icon_name: 'times',
          custom_classes: ''
        }"
      />
    </button>
    <blok-modal :blok="{}" :open="isOpen" @close="toggleModal()">
      <div class="text-4xl text-red-600 text-center">
          <blok-icon
            :blok="{
              icon: {
                icon: null
              },
              icon_type: 'fas',
              icon_name: 'exclamation-triangle'
            }"
          />
          </div>
        <div class="mt-3 md:text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium">{{ header }}</h3>
          <p class="mt-2 text-sm">{{ message }}</p>
          <p v-if="twelveHrs(appointment.datetime)" class="mt-2 text-sm !text-red-500">{{ notice }}</p>
        </div>
      <div class="mt-8 w-full flex px-8 justify-between gap-8 self-center text-center">
        <button
          v-if="isCancelable"
          class="
            w-1/2
            border border-transparent
            shadow-sm
            py-1
            rounded
            bg-red-600
            text-base
            font-medium
            text-white
            hover:bg-red-700
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          "
          style="transition: all 0.15s ease 0s"
          @click="cancelEvent(appointment)"
        >
          {{ cancel }}
          <svg
            v-if="isProgressCancellation"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
        <button class="bg-secondary-500 rounded w-1/2" @click="toggleModal()">
          {{ uncancel }}
        </button>
      </div>
    </blok-modal>
  </div>
</template>

<script>
import { defineComponent, useContext, ref, computed, inject } from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/date'
import AppointmentModel from '@/models/Appointment'

export default defineComponent({
  props: {
    appointment: {
      type: Object,
      default: () => {},
      required: true
    },
    header:    { type: String, required: true },
    message:   { type: String, required: true },
    notice:    { type: String, required: true },
    cancel:    { type: String, required: true },
    uncancel:  { type: String, required: true }
  },
  setup(props) {
    const { store, $axios, $toast, $dayjs } = useContext()
    const userData = inject('userData')
    const isProgressCancellation = ref(false)

    const isCancelable = computed(() => props.appointment.user_id === userData.value.id)

    async function cancelEvent(appointment) {
      if (!appointment) {
        $toast.error('Cancel error')
        return
      }
      isProgressCancellation.value = true
      const appointment_id = appointment?.id || id
      await $axios
        .$post(`/api/booking/appointments/${appointment_id}/cancel`)
        .then(() => {
          store.$repo(AppointmentModel).destroy(appointment_id)
          isProgressCancellation.value = false
        })
        .catch((e) => console.error(e))
      isProgressCancellation.value = false
    }

    const twelveHrs = (time) => {
      return $dayjs(time).diff($dayjs()) < 43200000
    }

    const isOpen = ref(false)
    const toggleModal = () => {
      isOpen.value = !isOpen.value
    }

    return {
      formatDate,
      cancelEvent,
      isProgressCancellation,
      isCancelable,
      twelveHrs,
      isOpen,
      toggleModal
    }
  }
})
</script>
