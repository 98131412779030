






















import { defineComponent, computed, useContext, onMounted, inject } from '@nuxtjs/composition-api'
import AppointmentsModel from '@/models/Appointment'
import useAppointments from '@/composables/useAppointments'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface Appointments extends StoryblokComponent<'BlokAppointments'> {
  custom_classes: string
  empty_bookings_message: string
  header: string
  message: string
  notice: string
  button: string
}

export default defineComponent({
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { store, $bus } = useContext()
    const userData = inject('userData')
    const { isCoach } = useAppointments()
    const appointments = computed(() => {
      if (!isCoach) {
        const now = new Date().getTime()
        const getAppointments =
          store.$repo(AppointmentsModel).with('order').where('user_id', userData.value?.id).get() || []

        const sortedAppointments = getAppointments.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
        return sortedAppointments.filter((item) => {
          const time = new Date(item.datetime).getTime()
          return time > now
        })
      } else {
        return (
          store
            .$repo(AppointmentsModel)
            .with('order')
            .orderBy('datetime', 'desc')
            .where('calendarID', userData.value?.user_coach_profile?.calendar_id)
            .orWhere('user_id', userData.value?.id)
            .get() || []
        )
      }
    })

    onMounted(() => {
      $bus.emit('refetchStore', 'appointments')
    })

    return {
      appointments
    }
  }
})
