

































import { defineComponent, inject, onMounted, PropType, ref } from '@nuxtjs/composition-api'
import { SwiperOptions } from 'swiper'
import helper from '@/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaSlider extends StoryblokComponent<'slider'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  space_between: number
  slides_per_view: number
  speed: number
  autoplay_delay: number
  autoplay_disable_on_interaction: boolean
  loop: boolean
  free_mode: boolean
  xs_space_between: number
  xs_slides_per_view: number
  sm_space_between: number
  sm_slides_per_view: number
  md_space_between: number
  md_slides_per_view: number
  lg_space_between: number
  lg_slides_per_view: number
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaSlider>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const classes = concatClasses(props.blok)
    const mySwiper = ref()
    const isStoryblokEdit = inject('storyblokEdit', ref(false))

    const swipperNavigation = (navigation: string) => {
      if (navigation === 'dots') {
        return {
          pagination: {
            el: '.swiper-dots',
            clickable: true
          }
        }
      } else if (navigation === 'arrows') {
        return {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      } else if (navigation === 'both') {
        return {
          pagination: {
            el: '.swiper-dots',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        }
      }
      return {}
    }

    const swiperOptions: SwiperOptions = {
      init: false,
      slidesPerView: props.blok.slides_per_view,
      spaceBetween: props.blok.space_between,
      loop: props.blok.loop,
      freeMode: props.blok.free_mode || false,
      ...swipperNavigation(props.blok.navigation),
      speed: props.blok.speed,
      cssMode: true,
      autoplay: {
        delay: props.blok.autoplay_delay,
        disableOnInteraction: props.blok.autoplay_disable_on_interaction || false
      },
      breakpoints: {
        1024: {
          slidesPerView: props.blok.lg_slides_per_view ? props.blok.lg_slides_per_view : props.blok.slides_per_view,
          spaceBetween: props.blok.lg_space_between ? props.blok.lg_space_between : props.blok.space_between
        },
        768: {
          slidesPerView: props.blok.md_slides_per_view ? props.blok.md_slides_per_view : props.blok.slides_per_view,
          spaceBetween: props.blok.md_space_between ? props.blok.md_space_between : props.blok.space_between
        },
        640: {
          slidesPerView: props.blok.sm_slides_per_view ? props.blok.sm_slides_per_view : props.blok.slides_per_view,
          spaceBetween: props.blok.sm_space_between ? props.blok.sm_space_between : props.blok.space_between
        },
        320: {
          slidesPerView: props.blok.xs_slides_per_view ? props.blok.xs_slides_per_view : props.blok.slides_per_view,
          spaceBetween: props.blok.xs_space_between ? props.blok.xs_space_between : props.blok.space_between
        }
      }
    }

    onMounted(() => {
      if (!isStoryblokEdit.value) {
        mySwiper.value?.init()
      }
    })

    return {
      swiperOptions,
      classes,
      dashify,
      mySwiper,
      isStoryblokEdit
    }
  }
})
