










import { defineComponent, PropType, inject, useContext, ref, unref, onMounted } from '@nuxtjs/composition-api'
import get from 'get-value'
import useHelper from '@/utils/helper'
import useTemplates from '~/utils/useTemplates'
import { StoryblokComponent } from '~/types/storyblok-vue'

export interface GaProvideValueEffect extends StoryblokComponent<'provide-value-effect'> {
  content: StoryblokComponent<any>[]
  custom_classes: string
  placeholder_prefix: string
  bus_event_name: string
  static_default: string
  inject_items_name: string
  inject_items_property_path: string
  value_property_path: string
  integrate_or_override: boolean
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaProvideValueEffect>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify, stringToJson } = useHelper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const nuxtContext = useContext()
    const value = ref({})

    const injectName = props.blok.inject_items_name || 'items'
    const injectItems = inject(injectName, [])

    const item = () => get(unref(injectItems), props.blok.inject_items_property_path, { default: unref(injectItems) })

    onMounted(() => {
      if (props.blok.static_default.length) {
        value.value = stringToJson(props.blok.static_default)
      } else {
        value.value = item() || {}
      }
    })

    nuxtContext.$bus.off(props.blok.bus_event_name)
    nuxtContext.$bus.on(props.blok.bus_event_name, (new_value) => {
      if (props.blok.integrate_or_override) {
        value.value = new_value || {}
      } else {
        value.value = { ...value.value, ...(new_value || {}) }
      }
    })

    const _generateItemAttributes = (content) => {
      const unrefed = unref(value)
      const pathValue = get(unrefed, props.blok.value_property_path, { default: unrefed })
      return generateItemAttributes(
        pathValue,
        content,
        props.blok.placeholder_prefix ? props.blok.placeholder_prefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      generateItemAttributes: _generateItemAttributes,
      value
    }
  }
})
