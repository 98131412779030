




























import { computed, defineComponent, inject, PropType, provide, ref, unref } from '@nuxtjs/composition-api'
import get from 'get-value'
import helper from '~/utils/helper'
import { StoryblokComponent } from '~/types/storyblok-vue'
import useTemplates from '~/utils/useTemplates'
import RepeatableItem from '~/components/list/RepeatableItem.vue'

export interface GaRepeatable extends StoryblokComponent<'repeatable'> {
  content: StoryblokComponent<any>[]
  content_after: StoryblokComponent<any>[]
  item_style: StoryblokComponent<any>[]
  custom_classes: string
  data_model: string
  inject_items_name: string
  provide_item_name: string
  inject_items_prop: string
  placeholder_prefix: string
  provide_data_model: boolean
  use_item_index: boolean
  type: 'div' | 'ul' | 'ol' | 'tr' | 'span' | 'section' | 'dl'
}

export default defineComponent({
  components: { RepeatableItem },
  props: {
    blok: {
      type: Object as PropType<GaRepeatable>,
      default: () => {}
    }
  },
  setup(props) {
    const { concatClasses, dashify } = helper()
    const { generateItemAttributes } = useTemplates()
    const classes = concatClasses(props.blok)
    const injectName = props.blok.inject_items_name || 'items'
    const defaultItems = ref([])
    const injectItems = inject(injectName, defaultItems)
    const itemIndex = inject('item_index', 0)
    const items = computed(() => {
      if (props.blok.inject_items_prop) {
        const getProp = props.blok.use_item_index
          ? itemIndex + '.' + props.blok.inject_items_prop
          : props.blok.inject_items_prop
        return get(unref(injectItems), getProp)
      }
      return unref(injectItems)
    })

    const activeId = 0

    provide('activeId', activeId)

    const generateRepeatableItemAttributes = (item: any) => {
      return generateItemAttributes(
        item,
        props.blok.item_style,
        props.blok.placeholder_prefix ? props.blok.placeholder_prefix : 'item'
      )
    }

    return {
      classes,
      dashify,
      generateRepeatableItemAttributes,
      items
    }
  }
})
