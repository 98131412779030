import { Model } from '@vuex-orm/core'
import Product from '~/models/Product'
import Calendar from '~/models/Calendar'
import AppointmentRoom from '~/models/AppointmentRoom'
import Appointment from '~/models/Appointment'

export default class AppointmentType extends Model {
  static entity = 'appointmentTypes'

  static fields() {
    return {
      id: this.uid(),
      product_id: this.attr(null),
      calendarIDs: this.attr(null),
      name: this.string(''),
      duration: this.number(0),
      price: this.string(''),
      display_topic: this.boolean(false),
      display_game_ranks: this.boolean(false),
      display_coach_info: this.boolean(false),
      product: this.belongsTo(Product, 'product_id'),
      calendars: this.hasManyBy(Calendar, 'calendarIDs'),
      appointmentRooms: this.hasMany(AppointmentRoom, 'appointmentTypeID'),
      appointments: this.hasMany(Appointment, 'appointmentTypeID')
    }
  }

  name!: string
  duration!: number
  price!: string
  display_topic!: boolean
  display_game_ranks!: boolean
  display_coach_info!: boolean
  product!: Product
}
