
























import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ImageSizesOptions } from '@nuxt/image'
import useHelper from '@/utils/helper'
import { StoryblokComponent, StoryblokAsset } from '~/types/storyblok-vue'

export interface GaImage extends StoryblokComponent<'image'>, ImageSizesOptions {
  custom_classes: string
  type: 'nuxt-picture' | 'nuxt-img'
  source: StoryblokAsset
  smart: boolean
  width: number
  height: number
  fit: boolean
  remove_lazy_loading: boolean
  fill_color: string
  format: string
  title: string
  alt: string
  quality: number
}

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<GaImage>,
      default: () => {}
    }
  },
  setup(props) {
    const { dashify, concatClasses } = useHelper()
    const classes = concatClasses(props.blok)

    const attr = {
      modifiers: {
        ...(props.blok.smart && { smart: props.blok.smart }),
        filters: {
          ...(props.blok.source && props.blok.source.focus && { focal: props.blok.source.focus }),
          ...(props.blok.fill_color && { focal: props.blok.fill_color })
        }
      },
      ...(classes && { class: classes }),
      ...(props.blok.fit && { fit: 'in' }),
      ...(props.blok.sizes && { sizes: props.blok.sizes }),
      ...(props.blok.format && { format: props.blok.format }),
      ...(props.blok.width && { width: props.blok.width }),
      ...(props.blok.height && { height: props.blok.height }),
      ...(props.blok.quality && { quality: props.blok.quality }),
      ...(props.blok.source && { src: props.blok.source.filename }),
      ...(props.blok.source && props.blok.source.title && { title: props.blok.source.title }),
      ...(props.blok.title && { title: props.blok.title }),
      ...(props.blok.source && props.blok.source.alt && { alt: props.blok.source.alt }),
      ...(props.blok.alt && { alt: props.blok.source.alt }),
      ...(!props.blok.remove_lazy_loading && { loading: 'lazy' })
    }

    return {
      dashify,
      attr
    }
  }
})
